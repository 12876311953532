import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "@src/components/Page";
import PropTypes from "prop-types";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { clearMessages, updateUserPassword } from "@src/actions/userActions";
import Loader from "@src/components/Loader";
import { getValidationSchema } from "@src/views/auth/RegisterView/onboardingHelpers";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { timeSnackbar } from "@src/utils/constants";
import { withSnackbar } from "@src/components/SnackBarComponent";
import PasswordStrengthMeter from "@src/components/PasswordStrengthMeter";

const useStyles = makeStyles(() => ({
  pageMargin: {
    "@media (max-width: 599px)": {},
    "@media (min-width: 600px)": {
      width: "360px !important",
      margin: "auto",
    },
  },
}));

const ForgotPasswordResetView = ({
  changeUserPassword,
  authReducers,
  removeMessages,
  ...props
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const classes = useStyles();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (authReducers) {
      if (authReducers.successMessage) {
        props.snackbarShowMessage(authReducers.successMessage, "success");
        removeMessages();
        setTimeout(() => {
          navigate(pathT("route.login"));
        }, timeSnackbar);
      }
      if (authReducers.warningMessage) {
        props.snackbarShowMessage(authReducers.warningMessage);
        removeMessages();
      }
      if (authReducers.error?.message) {
        props.snackbarShowMessage(authReducers.error?.message);
        removeMessages();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [authReducers]);

  const onSubmit = async (fields, { setStatus, setSubmitting }) => {
    setStatus();
    sessionStorage.removeItem("user");
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const userid = urlParams.get("email").replace(/ /g, "+");

    if (code === undefined || code == null) {
      props.snackbarShowMessage(t("InvalidActionView.SomethingWentWrong"));
      setTimeout(() => {
        navigate(pathT("route.login"));
      }, timeSnackbar);
      setSubmitting(false);
    }
    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
    try {
      await changeUserPassword(code, userid, fields.password);
      props.snackbarShowMessage("Success", "success");
    } catch (error) {
      props.snackbarShowMessage("Error", "error");
    }
    setTimeout(() => {
      navigate(pathT("route.login"));
    }, timeSnackbar);
  };

  return (
    <Page title={t("ForgotPasswordResetView.Title")}>
      <Box
        display="flex"
        flexDirection="center"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.pageMargin}>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={getValidationSchema(t, { password: true })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} className={classes.pageMargin}>
                <Box className={classes.pageMargin}>
                  <Typography variant={isSmallScreen ? "h3" : "h1"}>
                    {t("ForgotPasswordResetView.ResetYourPassword")}
                  </Typography>
                </Box>
                <Box mt={2} mr={2} ml={2}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={t("ForgotPasswordResetView.NewPassword")}
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={toggleShowPassword} size="large">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <PasswordStrengthMeter password={values.password} />
                </Box>
                {isSubmitting && <Loader />}
                <Box>
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("ForgotPasswordResetView.ResetPassword")}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

ForgotPasswordResetView.propTypes = {
  changeUserPassword: PropTypes.func.isRequired,
  removeMessages: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  authReducers: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  changeUserPassword: (code, userid, password) =>
    dispatch(updateUserPassword(code, userid, password)),
  removeMessages: () => {
    dispatch(clearMessages());
  },
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordResetView),
);
