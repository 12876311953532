import Button from "@openup/shared/components/Button/Button";
import ArticleCard, { getLoadingCards } from "@src/components/ArticleCard";
import {
  ContentItem,
  ContentEvent,
  useGetContents,
  useSaveContentEvent,
  ContentEventType,
} from "@src/queries/contents";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { CONTENTS_TYPE_LIBRARY } from "@src/utils/constants";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Theme, useMediaQuery } from "@mui/material";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

const CARDS_LOADING_AMOUNT = 6;

interface LatestArticlesProps {
  className?: string;
  classNameItem?: string;
}

const ViewAllButton = ({ className = "" }: { className?: string }) => {
  const { t, pathT } = useTranslatedNavigate();
  const sendDataToGTM = useGTMDispatch();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Button
      href={pathT("route.library")}
      variant="tertiary"
      className={className}
      onMouseUp={(e) => {
        if (e.button === 0 || e.button === 1) {
          sendDataToGTM({
            event: "My OpenUp - Dashboard Page - Articles View All Click",
            language,
          });
        }
      }}
    >
      {t("DashboardView.Contents.ViewAll")}
      <KeyboardArrowRightIcon />
    </Button>
  );
};

const LatestArticles = ({
  className = "",
  classNameItem = "",
}: LatestArticlesProps) => {
  const { t } = useTranslatedNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const isSmallScreen = useMediaQuery((x: Theme) => x.breakpoints.down("sm"));
  const sendDataToGTM = useGTMDispatch();

  const { data, isLoading, isError } = useGetContents({
    language,
    type: CONTENTS_TYPE_LIBRARY,
    theme: null,
    limit: CARDS_LOADING_AMOUNT,
    contentTypePagination: null,
  });

  const { mutateAsync: saveEvent } = useSaveContentEvent();

  const articleClick = (article: ContentItem) => {
    const event: ContentEvent = {
      contentId: article.id,
      eventType: ContentEventType.Click,
    };
    sendDataToGTM({
      event: "My OpenUp - Dashboard Page - Article Click",
      language,
      article_id: article.id,
    });
    saveEvent(event);
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <h3 className="h3">{t("DashboardView.Contents.Title")}</h3>
        <ViewAllButton className="hidden sm:inline-flex" />
      </div>
      {!isLoading && isError ? (
        <p className="h4 mt-4">{t("InvalidActionView.SomethingWentWrong")}</p>
      ) : (
        <ul className="grid grid-cols-2 md:grid-cols-3 flex-wrap list-none mt-6 p-0 gap-8">
          {isLoading && getLoadingCards(CARDS_LOADING_AMOUNT)}
          {!isLoading &&
            data?.pages
              ?.map((page) => page!.data)
              .flat()
              ?.map((item) => (
                <li key={item.id} className={classNameItem}>
                  <ArticleCard
                    onMouseUp={(e) => {
                      if (e.button === 0 || e.button === 1) {
                        // left and middle button clicked
                        articleClick(item);
                      }
                    }}
                    article={item}
                    variant={isSmallScreen ? "compact" : "standard"}
                    isBackgroundEnabled={!isSmallScreen}
                  />
                </li>
              ))}
        </ul>
      )}
      <ViewAllButton className="sm:hidden w-full mt-6" />
    </div>
  );
};

export default LatestArticles;
