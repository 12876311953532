import { useRef, useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { withSnackbar } from "@src/components/SnackBarComponent";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import theme from "@src/theme";
import { Box, ThemeProvider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { closeWebview, isMobileAppView } from "@src/utils/mobileHelper";
import OnboardingLayoutTopBar from "./TopBars/OnboardingLayoutTopBar";

const useStyles = makeStyles(({ breakpoints, colors }) => ({
  root: {
    minHeight: "100%",
    backgroundImage: "url('/images/onboarding/bg-top-right.svg')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
    backgroundSize: "contain",
    [breakpoints.up("sm")]: {
      backgroundImage:
        "url('/images/onboarding/bg-top-right.svg'), url('/images/onboarding/bg-bottom-left.svg')",
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: "right top, left bottom",
      backgroundSize: "288px, 460px",
    },
  },
  signupMethodPage: {
    backgroundColor: colors.primaryGreenDark,
    backgroundImage: "none",
    [breakpoints.up("sm")]: {
      backgroundColor: "transparent",
    },
  },
}));

const removeTrailingSlash = (path) => {
  return path.replace(/\/+$/g, "");
};

const OnboardingLayout = ({ isRegister = false }) => {
  const classes = useStyles();
  const step = useRef(0);
  const [isFlowSetup, setIsFlowSetup] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(1);

  const stepsToSkip = useRef([]);
  const preventGoingBack = useRef(false);
  const stepsMap = useRef(new Map());
  const reverseStepsMap = useRef(new Map());
  const baseLocation = useLocation();

  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const [, setValue] = useState(0); // Used to force a rerender, read below
  const forceRerender = () => setValue((value) => value + 1);

  // Please note:
  // This is being used because having the step as a state does not interact well with the new back button implementation
  // What was happening was that fetching the step value inside the listener would retrieve it's initial value (0) and not the current one
  // Switching it to useRef seems to work properly. The manual re-rendering is so that the UI updates - this was implicit with useState
  // With the exception of isFlowSetup, the step is the only thing that needs to rerender the OnboardingLayout, hence the forceRerender being used only here
  const setStep = (value) => {
    step.current = value;
    forceRerender();
  };

  const setupFlowSteps = (steps) => {
    const stepsArray = Object.keys(steps).map((key) => steps[key]);

    const temporaryMap = new Map();
    const temporaryReverseMap = new Map();

    stepsArray.forEach((value, index) => {
      temporaryMap.set(index, value);
      temporaryReverseMap.set(value, index);
    });

    stepsMap.current = temporaryMap;
    reverseStepsMap.current = temporaryReverseMap;

    stepsArray.forEach((key) => {
      // for the first step
      if (key === "") return;
      if (!baseLocation.pathname.includes(pathT(t(key)))) {
        navigate(baseLocation.pathname.replace(`${pathT(t(key))}`, ""), {
          replace: true,
        });
      }
    });

    setIsFlowSetup(true);
  };

  const getStepNumber = (value) => {
    if (reverseStepsMap.current.has(value))
      return reverseStepsMap.current.get(value);
    if (stepsMap.current.has(value)) return value;
    return null;
  };

  const getStepsAmount = () => stepsMap.current.size;

  const getStepName = (value) => {
    if (stepsMap.current.has(value)) return stepsMap.current.get(value);
    if (reverseStepsMap.current.has(value)) return value;
    return null;
  };

  const goToStep = (newStep) => setStep(newStep);

  const nextStep = () => {
    let stepToGoTo = step.current + 1;
    if (!stepsMap.current.has(stepToGoTo)) return;

    while (stepsToSkip.current.includes(stepToGoTo)) {
      stepToGoTo += 1;
    }
    setStep(stepToGoTo);
  };

  const goToPreviousStep = () => {
    let stepToGoTo = step.current - 1;

    if (preventGoingBack.current) return;

    while (stepsToSkip.current.includes(stepToGoTo)) {
      stepToGoTo -= 1;
    }

    if (stepToGoTo >= 0) {
      setStep(stepToGoTo);
    } else if (isMobileAppView()) {
      closeWebview();
    } else {
      navigate(-1);
    }
  };

  const setPreventGoingBack = (value) => {
    preventGoingBack.current = value;
  };

  const resetLayoutState = () => {
    stepsToSkip.current = [];
    preventGoingBack.current = false;
    reverseStepsMap.current = new Map();
    stepsMap.current = new Map();
    setIsFlowSetup(false);
    setStep(0);
  };

  const navigateToUrl = (newUrl) => {
    if (!newUrl) return;

    baseLocation.pathname = newUrl;
    resetLayoutState();
    navigate(newUrl, { replace: true });
  };

  useEffect(() => {
    if (stepsMap.current.size <= 0) {
      return;
    }
    if (getStepName(step.current) === "") {
      // to avoid a trailing slash
      window.history.pushState(
        null,
        "",
        removeTrailingSlash(baseLocation.pathname),
      );
    } else {
      window.history.pushState(
        null,
        "",
        `${removeTrailingSlash(baseLocation.pathname)}/${t(
          getStepName(step.current),
        )}`,
      );
    }
    setCompletionPercentage((step.current / (getStepsAmount() - 1)) * 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [step.current, stepsMap.current, setCompletionPercentage]);

  useEffect(() => {
    window.addEventListener("popstate", goToPreviousStep);

    // Removes the listener on clean up
    return () => window.removeEventListener("popstate", goToPreviousStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on first load
  }, []);

  return (
    <Box
      className={`${classes.root} ${
        step.current === 6
          ? `${classes.signupMethodPage} signupMethodPageContainer`
          : ""
      }`}
    >
      <ThemeProvider theme={theme}>
        {(step.current !== 0 || isRegister) && (
          <OnboardingLayoutTopBar
            percentage={
              step.current === 0 && isRegister
                ? undefined
                : completionPercentage
            }
            onBackButtonClick={
              step.current === 0 && isRegister ? undefined : goToPreviousStep
            }
          />
        )}
        <Outlet
          context={{
            step: getStepName(step.current),
            nextStep,
            goToStep,
            goToPreviousStep,
            navigateToUrl,
            setupFlowSteps,
            isFlowSetup,
            setPreventGoingBack,
            stepsMap,
            getStepNumber,
            getStepsAmount,
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default withSnackbar(OnboardingLayout);
