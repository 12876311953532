import colors from "@src/theme/colors";

export const enterKey = 13;
export const backspaceKey = 8;

export const MINIMAL_AGE = 17;
export const MAXIMUM_AGE = 119;

export const TWO_FACTOR_REQUEST_STATES = {
  IDLE: "idle",
  PENDING: "pending",
  SUCCESS: "success",
  FAILURE: "failure",
  INVALID: "invalid",
  EXPIRED: "expired",
};

export const PhoneTwoFactorCodeRequestMethod = "Phone";
export const AuthenticatorTwoFactorCodeRequestMethod = "AuthenticatorKey";

export const USER_ROLES = {
  GUEST: 1,
  CLIENT: 2,
};

export const USER_DETAILS = {
  email: "",
  firstName: "",
  lastName: "",
  yearOfBirth: "",
  signUpToNewsletter: false,
};

export const guestUserLoginActions = {
  reschedule: "reschedule",
  cancel: "cancel",
  rebook: "rebook",
  followup: "followup",
  intro: "intro",
  medicalCheckIn: "medicalCheckIn",
  checkIn: "checkin",
  bookSession: "bookSession",
  sessionNotes: "sessionNotes",
  videoCall: "videocall",
  confirmSpace: "confirmSpace",
  explicitConsent: "explicitConsent",
};

export const passwordStrengths = {
  weak: "weak",
  medium: "medium",
  high: "high",
};

export const apiPaths = {
  employers: "v1/employers",
  registerUser: "v1/users/register-user",
  updateUser: "v2/users/update-user",
  setUserPreferences: "v1/users/preferences",
  setUnconfirmedUserPreferences: "v1/unconfirmed/preferences/preferences",
  getUserPreferences: "v1/users/preferences",

  setUserCurrentMoods: "v1/users/currentMoods",
  setUnconfirmedUserCurrentMoods: "v1/unconfirmed/preferences/currentMoods",
  getUserCurrentMoods: "v1/users/currentMoods",

  sessionType: "v1/tags/session-type",

  profile: "v2/users/profile",
  guestProfile: "v1/users/guest-profile",
  emailRegistered: "v1/users/email-registered",
  sendVerificationEmail: "v1/users/send-email-verification",
  confirmUser: "v1/users/confirm-user",
  nonConfirmedClientIsRegisteredEmail: "v1/users/session-email-registered",
  verifyAccountByEmail: "v1/users/email-registered",
  login: "v2/users/login",
  logout: "v1/users/logout",
  sendPasswordResetEmail: "v1/users/send-password-reset-email",
  resetPassword: "v1/users/reset-password",
  updateLanguage: "v1/users/update-language",
  refreshToken: "v1/users/refresh-token",
  loginEmail: "v1/users/send-booking-login-email",
  changeUserPassword: "v1/users/change-user-password",
  updateUserPhoneNumber: "v1/users/phone-number",
  updateUserPhoneNumberWithToken: "v1/users/phone-number-with-token",

  registerGuestUserAfterCheckIn:
    "v1/external-Login/register-guest-user-after-check-in",
  providerChallenge: "v1/external-login/provider-challenge",
  externalLogin: "v2/external-login/login",
  guestLogin: "v1/external-login/login-guest",
  externalSignUp: "v1/external-login/register",
  externalSignUpCodeFlow: "v2/external-login/register-code-flow",
  loginExternalCodeFlow: "v2/external-login/login-code-flow",

  getTripettoFormDefinition: "v1/form/form-definition",
  getTripettoThemeFormDefinition: "v1/form/themes-form-definition",
  setTripettoFormAnswers: "v1/form/form-answers",
  setTripettoUnconfirmedFormAnswers: "v1/unconfirmed/form/form-answers",
  setQuestionListAnswers: "v1/form/question-list-answers",
  setUnconfirmedQuestionListAnswers:
    "v1/unconfirmed/form/question-list-answers",
  getQuestionListAnswers: "v1/form/question-list-answers",
  getQuestionListScores: "v1/form/question-list-scores",
  getQuestionListDefinition: "v1/form/question-list-definition",
  getMedicalCheckInResult: "v1/form/question-list-answers-by-theme",

  setUserFocusedThemes: "v1/themes/focused-themes",
  setUnconfirmedUserFocusedThemes: "v1/unconfirmed/themes/focused-themes",
  getUserThemes: "v1/themes/user-themes",
  getUserMedicalThemes: "v1/themes/user-themes/Medical",
  updateUserTheme: "v1/themes/user-themes",
  getThemes: "v1/themes",

  getConsult: "v1/consults",

  twoFactorAuthentication: "v1/users/2fa/",
  twoFactorAuthenticationGenerate: "v1/users/2fa/send-code",
  twoFactorAuthenticationToggle: "v1/users/2fa/state",
  twoFactorAuthenticationValidate: "v1/users/2fa/validate",

  registerGuestUser: "v1/guest",
  registerGuestUserAfterBooking:
    "v1/scheduler/register-user-and-confirm-consult",
  availableTimeSlots: "v1/scheduler/time-slots/available",
  getAvailableLanguages: "v1/tags/available-languages",
  cancelConsult: "v1/scheduler/consult",
  bookConsult: "v1/scheduler/book-consult",
  bookNonConfirmedConsult: "v1/scheduler/book-non-confirmed-consult",
  rescheduleConsultWithPsychologist:
    "v1/scheduler/reschedule-with-psychologist",
  rescheduleConsultWithTags: "v1/scheduler/reschedule-with-tags",
  confirmConsult: "v1/scheduler/confirm-consult",
  lastCompletedConsult: "v1/scheduler/last-completed-consult",
  nonConfirmedConsult: "v1/scheduler/non-confirmed-consult",
  timezones: "v1/scheduler/timezones",
  psychologistTags: "v1/clients/psychologists/psychologist-tags",
  getPsychologistByUrlHandle: "v1/clients/psychologists/url-handle",
  getPsychologistById: "v1/clients/psychologists",

  spaces: "v1/space-users",
  consultSpaces: "v1/consult-spaces",
  confirmSpace: "v1/spaces/confirm",
  videoCalling: "v1/video-calling",
  companySsoSupport: "v1/employers/sso",

  contents: "v1/contents",
  featureFlags: "v1/featureflags",
  courses: "v1/contents/courses",
  courseLesson: "v1/contents/lessons",
};

export const TOP_SCORE_QUESTION = 3;
export const TOP_SCORE_NUMBER = 5;
export const LOW_SCORE_THRESHOLD = 1.0;
export const MEDIUM_SCORE_THRESHOLD = 2.5;
export const HIGH_SCORE_THRESHOLD = 4.0;
export const VALUE_LABEL_HEIGHT = 72;
export const MAX_FOCUSED_THEMES = 3;
export const BACKGROUND = {
  GREEN_BACKGROUND: "GREEN_BACKGROUND",
  SKIN_BACKGROUND: "SKIN_BACKGROUND",
};

export const HOVER_TYPE = {
  INSIGHTS: "INSIGHTS",
  EDUCATION: "EDUCATION",
  BEST_PRACTICES: "BEST_PRACTICES",
  NONE: "NONE",
};

export const QUESTION_LIST_DEFINITIONS = {
  RESILIENCE: "resilience-checkin",
};

export const TAG_TYPES = {
  SESSION_LANGUAGE: "SessionLanguage",
  SESSION_TYPE: "SessionType",
  USER_STATUS: "Status",
  USER_MINDSET: "Mindset",
  PSYCHOLOGIST_LANGUAGE: "PsychologistLanguage",
  PSYCHOLOGIST_STATUS: "PsychologistStatus",
};

export const getClientTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const ONBOARDING_OVERVIEW_CURRENT_PAGE = {
  NAME: "NAME",
  YEAR_OF_BIRTH: "YEAR_OF_BIRTH",
  FEELING: "FEELING",
  THANKS: "THANKS",
  REFERRAL: "REFERRAL",
  REGISTRATION: "REGISTRATION",
};

export const ONBOARDING_VIEW_REFERRAL_STATUS = {
  COMPANY: "company",
  FRIENDS: "friends",
  NO: "no",
};

export const SESSION_TYPE = {
  SPACE: "Space",
  CONSULT: "Consult",
};

export enum IConsultType {
  GENERAL = "general",
  MINDFULNESS = "mindfulness",
  MEDICAL = "medical",
  PHYSICAL_WELLBEING = "physical-wellbeing",
}
export const CONSULT_TYPE = {
  GENERAL: "general",
  MINDFULNESS: "mindfulness",
  MEDICAL: "medical",
  PHYSICAL_WELLBEING: "physical-wellbeing",
};

export enum ISpaceType {
  MASTERCLASS = "masterclass",
  GROUPSESSION = "groupsession",
  GROUPSESSION75MINS = "groupsession75Mins",
  MINDFULNESS = "mindfulness",
}

export const CONSULT_TYPES = [CONSULT_TYPE.GENERAL, CONSULT_TYPE.MINDFULNESS];

export const SESSION_NOTES_SECTIONS = {
  REASON_BOOK: "reasonBook",
  SESSION_DISCUSSED: "sessionDiscussed",
  HELP_NEEDED: "helpNeeded",
  PROVIDER: "provider",
  PROVIDER_NAME: "providerName",
  NEXT_STEPS: "nextSteps",
};

export const PSYCHOLOGIST_STATUS = {
  ACTIVE_ACCEPTING: "active_accepting",
  ACTIVE_NOT_ACCEPTING: "active_not_accepting",
  INACTIVE: "inactive",
};

export const THEME_KEY = {
  CONFIDENCE: "Confidence",
  STRESS: "Stress",
  WORK: "Work",
  PURPOSE: "Purpose",
  RELATIONSHIPS: "Relationships",
  HEALTH: "Health",
  SLEEP: "Sleep",
  MINDFULNESS: "Mindfulness",
};

export const MEDICAL_THEME_KEY = {
  BREATHING: "Breathing",
  DAILY_ENERGY: "DailyEnergy",
  INTOXICATION: "Intoxications",
  MENTAL_HEALTH: "MentalHealth",
  NUTRITION: "Nutrition",
  PHYSICAL_ACTIVITY: "PhysicalActivity",
  SLEEP_SATISFACTION: "SleepSatisfaction",
};

export const displayLogic = {
  0: {
    category: "Untracked",
    color: "#808080",
  },
  1: {
    category: "Low",
    color: colors.primaryDarkBlue,
  },
  2: {
    category: "Medium",
    color: colors.primaryBlue,
  },
  3: {
    category: "High",
    color: colors.primaryGreen,
  },
};

export const displayLogicAllQuestions = {
  0: {
    category: "Low",
    color: colors.primaryDarkBlue,
  },
  1: {
    category: "Medium",
    color: colors.primaryBlue,
  },
  2: {
    category: "Medium",
    color: colors.primaryBlue,
  },
  3: {
    category: "High",
    color: colors.primaryGreen,
  },
};

export const pageSize = 10;

export const STATUS = {
  SCHEDULED: "Scheduled",
  RESCHEDULED: "Rescheduled",
  CANCELLED_BY_CLIENT: "CancelledByClient",
  CANCELLED_BY_OPENUP: "CancelledByOpenUp",
  COMPLETED: "Completed",
  MISSED_BY_CLIENT: "MissedByClient",
  MISSED_BY_OPENUP: "MissedByOpenUp",
  MISSED_BY_BOTH: "MissedByBoth",
};

export const RESPONSE_STATUS = {
  OK: 200,
  TWO_FACTOR_AUTH_PENDING_ERROR_CODE: 209,
  BAD_REQUEST: 400,
  UNAUTHORISED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  NOT_FOUND: 404,
};

export const spaceCreationErrors = {
  SPACE_USER_EXPIRED: "UnconfirmedDataExpired",
  USER_NOT_FOUND: "UserNotFound",
  SPACE_USER_REGISTRATION_FAILED: "SpaceUserRegistrationFailed",
};

export const timeSnackbar = 3000;

export const LANGUAGE_TYPES = {
  AR: "ar-AR",
  AR_MA: "ar-MA",
  BS: "bs-BA",
  DE: "de-DE",
  EN_US: "en-US",
  EN: "en-GB",
  ES: "es-ES",
  FL: "nl-BE",
  FR: "fr-FR",
  HR: "hr-HR",
  IT: "it-IT",
  NL: "nl-NL",
  PL: "pl-PL",
  PT: "pt-PT",
  RU: "ru-RU",
  SR: "sr-RS",
  TR: "tr-TR",
  UK: "uk-UA",
  ZH: "zh-CN",
  Zk: "zk-CN",
  BG: "bg-BG",
  DA: "da-DK",
  FI: "fi-FI",
  SO: "so-SO",
  NO: "nb-NO",
  HU: "hu-HU",
  SK: "sk-SK",
  GR: "el-GR",
  AR_EG: "ar-EG",
  AR_TN: "ar-TN",
  CS: "cs-CZ",
  SL: "sl-SL",
  AF: "af-ZA",
  DE_CH: "de-CH",
};

export const LANGUAGE_TYPES_SHORT_FORMS = {
  AR: "ar",
  BS: "bs",
  DE: "de",
  EN: "en",
  ES: "es",
  FL: "fl",
  FR: "fr",
  HR: "hr",
  IT: "it",
  NL: "nl",
  PL: "pl",
  PT: "pt",
  RU: "ru",
  SR: "sr",
  TR: "tr",
  UK: "uk",
  ZH: "zh",
  BG: "bg",
  DA: "da",
  FI: "fi",
  SO: "so",
  NO: "no",
  HU: "hu",
  SK: "sk",
  GR: "gr",
  SL: "sl",
  AF: "af",
  ID: "id",
  JA: "ja",
  MFE: "mfe",
};

export const LANGUAGE_TYPES_PHONE_PICKER = {
  EN: "gb",
  DE: "de",
  NL: "nl",
  FR: "fr",
  ES: "es",
  BE: "be",
};

export const PRODUCTS_KEY = {
  Sessions: "Sessions",
  Spaces: "Spaces",
  SelfGuidedCare: "SelfGuidedCare",
};

export const SSO_PROVIDER = {
  Pwc: "pwc",
  IPractice: "ipractice",
  Microsoft: "Microsoft",
};

export const CONTENTS_TYPE_LIBRARY = "article";
