import { useState, useRef } from "react";
import { TextField, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";

const useStyles = makeStyles(({ spacing, colors }) => ({
  autocompleteInput: {
    backgroundColor: colors.primarySkin,
    paddingLeft: `${spacing(2)}!important`,
    height: 48,
    borderRadius: "30px 0 0 30px",
  },
  endAdornment: {
    backgroundColor: colors.primarySkin,
    height: 54,
    borderRadius: "0 30px 30px 0",
    top: 4,
    right: 4,
    display: "flex",
    justifyContent: "center",
  },
  popupIndicator: {
    padding: 16,
    marginRight: 0,
  },
  popper: {
    opacity: 1,
    backgroundColor: colors.primarySkin,
    borderRadius: spacing(3),
    boxShadow: "none",
    paddingTop: spacing(5),
    top: `${spacing(-8)} !important`,
  },
  popperItem: {
    opacity: 1,
    backgroundColor: colors.primarySkin,
  },
  autoComplete: ({ isSmallScreen }) => ({
    width: isSmallScreen ? "98%" : spacing(45),
    marginBottom: spacing(6),
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: spacing(-2),
  }),
  clearIndicator: {
    marginRight: spacing(1),
  },
  unorderedList: {
    boxShadow: "none",
  },
  inputContainer: {
    zIndex: 1500,
  },
}));

const AutoCompleteLanguage = ({
  availableLanguages,
  handlePressLanguageCard,
}) => {
  const myRef = useRef(null);
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("lg"));
  const classes = useStyles({ isSmallScreen });
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslatedNavigate();

  const executeScroll = (ref) => {
    window.scrollTo({
      top: ref.current.getBoundingClientRect().top,
      left: 0,
      behavior: "smooth",
    });
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => option.translations.join(""),
  });

  return (
    <Autocomplete
      className={classes.autoComplete}
      clearOnBlur={false}
      freeSolo
      onClose={() => setIsOpen(false)}
      open={isOpen}
      onFocus={() => {
        setIsOpen(true);
        if (isSmallScreen) {
          executeScroll(myRef);
        }
      }}
      id="languageId"
      ref={myRef}
      options={availableLanguages}
      value={availableLanguages.find((language) => language.name === language)}
      filterOptions={filterOptions}
      onChange={(_, newValue) => {
        if (newValue?.code) {
          handlePressLanguageCard(newValue?.code);
        }
      }}
      getOptionLabel={(option) => t(`SessionLanguage.${option.code}`) || ""}
      classes={{
        clearIndicator: classes.clearIndicator,
        endAdornment: classes.endAdornment,
        input: classes.autocompleteInput,
        popupIndicator: classes.popupIndicator,
        popper: classes.popper,
        listbox: classes.popperItem,
        paper: classes.unorderedList,
        inputRoot: classes.inputContainer,
      }}
      renderInput={(params) => {
        params.InputProps.disableUnderline = true;
        return (
          <TextField
            variant="standard"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={t("Booking.Language.SearchPlaceholder")}
          />
        );
      }}
    />
  );
};

export default AutoCompleteLanguage;
