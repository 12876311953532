import { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "@mui/styles";

import colors from "@src/theme/colors";

const useStyles = makeStyles((theme) => ({
  autocompleteInput: {
    paddingBottom: "0!important;",
    backgroundColor: colors.primarySkin,
    paddingLeft: `${theme.spacing(2)}!important`,
    paddingTop: "2px !important",
    height: 48,
    borderRadius: 30,
    "&:has(+.MuiAutocomplete-endAdornment)": {
      borderRadius: "30px 0 0 30px !important",
    },
  },
  endAdornment: {
    backgroundColor: colors.primarySkin,
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    height: 50,
    borderRadius: "0 30px 30px 0",
    top: 0,
    right: 0,
  },
  popupIndicator: {
    padding: 16,
    marginRight: 0,
  },
  popper: {
    opacity: 1,
    background: "white",
  },
}));

const AutocompleteWrapper = ({
  id,
  options,
  onChange,
  placeholder,
  value,
  getOptionDisabled,
  isDisabled = false,
  popperStyle = undefined,
  className = "",
}) => {
  const classes = useStyles();
  const dropdownTriggerLength = 2;

  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <Autocomplete
      data-testid="combobox"
      id={id}
      forcePopupIcon={false}
      open={dropdownVisible}
      onInputChange={(changeEvent, changedValue) => {
        // Show dropdown only if the user has typed at least [dropdownTriggerLength] characters,
        if (changeEvent && changedValue) {
          if (changedValue.trim().length >= dropdownTriggerLength) {
            setDropdownVisible(true);
          } else {
            setDropdownVisible(false);
          }
        }
      }}
      onClose={() => setDropdownVisible(false)}
      options={options}
      getOptionDisabled={getOptionDisabled}
      disabled={isDisabled}
      value={value}
      onChange={onChange}
      getOptionLabel={(option) => option?.text || ""}
      classes={{
        root: className,
        endAdornment: classes.endAdornment,
        input: classes.autocompleteInput,
        popupIndicator: classes.popupIndicator,
        popper: popperStyle || classes.popper,
      }}
      renderInput={(params) => {
        params.InputProps.disableUnderline = true;
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField variant="standard" {...params} placeholder={placeholder} />
        );
      }}
    />
  );
};

export default AutocompleteWrapper;
